import { React, useState, useEffect } from "react";
import { Formik, Form as Formx } from "formik";
import GenericInput from "./genericInput";
import GenericButton from "./genericButton";
import ErrorMessage from "./inputs/errorMessage";
import { schema, getYup } from "../utils/yup";
import { Typography, Grid, Avatar, Stack } from "@mui/material";
import { Icons } from "../utils/icons-material";
import FooterContainer from "./footerContainer";
import themeKym from "../utils/themes/ThemeKym";

const Form = ({ form, btnNext, btnBefore }) => {
  let validates = {};
  let initialValue = {};
  const [props, setProps] = useState({ width: window.innerWidth });
  const classesKym = themeKym(props);
  const section = form.section;
  const footer = form.footer ? form.footer.fields : [];
  const btns = form.actionsButtons;
  const findFieldsValidations = (fields) => {
    fields.forEach(({ props, validations, data }) => {
      if (props.type === "column") {
        findFieldsValidations(props.fields);
      } else {
        initialValue[props.name] = props.value ?? "";
        validates[props.name] = schema(
          validations,
          props.type,
          data ?? [].length > 0 ? data.slice(1).map((x) => x.label) : []
        );
      }
    });
  };

  const validaciones = (fields) => {
    fields.forEach(({ props, validations, data }) => {
      if (props.type === "column") {
        validaciones(props.fields);
      } else {
        validates[props.name] = schema(
          validations,
          props.type,
          data ?? [].length > 0 ? data.slice(1).map((x) => x.label) : []
        );
      }
    });
  };

  const valoresIniciales = (fields) => {
    fields.forEach(({ props }) => {
      if (props.type === "column") {
        valoresIniciales(props.fields);
      } else {
        initialValue[props.name] = props.value ?? "";
      }
    });
  };

  const addErr = (field, err) => {
    initialValue = {};
    section.forEach((sec) => {
      validaciones(sec.fields);
      findFieldsValidationsWithRadio(sec);
    });

    if (footer.length > 0) {
      footer.forEach(({ props, validations }) => {
        initialValue[props.name] = "";
        validates[props.name] = schema(validations, props.type, []);
      });
    }

    for (let i in err) {
      if (!initialValue.hasOwnProperty(i)) {
        delete err[i];
      }
    }
    field.errors = err;
    return field;
  };

  const findFieldsValidationsWithRadio = (sec) => {
    if (sec.hasOwnProperty("radio")) {
      if (initialValue[sec.radio.name] === sec.radio.value) {
        valoresIniciales(sec.fields);
      }
    } else {
      valoresIniciales(sec.fields);
    }
  };

  section.forEach((sec) => {
    findFieldsValidations(sec.fields);
  });

  if (footer.length > 0) {
    footer.forEach(({ props, validations }) => {
      initialValue[props.name] = "";
      validates[props.name] = schema(validations, props.type, []);
    });
  }

  const sectionBuild = (sec, j, values) => {
    return (
      <Grid
        className={`${classesKym.sectionContainer} ${sec.sectionName}`}
        id={"sec-" + sec.id}
        key={j}
        item
        xs={11}
        md={12 / (section.length >= 3 ? 3 : section.length)}
      >
        <Stack direction="row" spacing={2} mb={3}>
          {sec.sectionName !== "ghost" ? (
            <Avatar className={classesKym.AvatarForm}>{Icons(sec.icon)}</Avatar>
          ) : (
            ""
          )}
          <Typography
            align="left"
            className={classesKym.TitleSection}
            gutterBottom
            component="div"
          >
            {sec.title}
          </Typography>
        </Stack>
        {sec.fields.map((field) => (
          <div key={field.props.id} style={{ marginTop: "20px" }}>
            <GenericInput
              {...addErr(field, values.errors)}
              {...values}
              eventRadioBtn={eventRadioButton}
              onchange={OnDependence}
            />
            <ErrorMessage name={field.props.name} errors={values.errors} />
          </div>
        ))}
      </Grid>
    );
  };

  const eventRadioButton = (name, value) => {
    initialValue = {};
    initialValue[name] = value;
    section.forEach((sec) => {
      validaciones(sec.fields);
      findFieldsValidationsWithRadio(sec);
    });
  };

  const OnDependence = (item, value) => {
    let dependence = item.props.dependence;
    let isFirstItem = value === item.data[0].label;

    if (dependence) {
      let sec = section.find((obj) => {
        return obj.sectionName === dependence.split(".")[0];
      });

      let select = sec.fields.find(({ props }) => {
        return props.name === dependence.split(".")[1];
      });
      if (select.data[0].dependence === undefined) {
        let depend = item.data.find(
          (x) => x.defaulValue.toUpperCase() === value.toUpperCase()
        );
        let data = select.data.find(
          (x) => x.defaulValue.toUpperCase() === depend.dependence.toUpperCase()
        );
        item.setFieldValue(`${select.props.name}`, data.defaulValue);
      } else {
        item.setFieldValue(`${select.props.name}`, select.data[0].label);
        if (isFirstItem) {
          item.setFieldValue(`data_${select.props.name}`, select.data);
        } else {
          let data = select.data.filter(
            (x) =>
              x.dependence.toUpperCase() === value.toUpperCase() ||
              x.dependence === ""
          );
          item.setFieldValue(`data_${select.props.name}`, data);
        }
      }
    }
  };

  const handleResize = () => {
    setProps({
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize={true}
      validationSchema={getYup(validates)}
      onSubmit={(valores) => {
        let data = [];
        data = valores;
        btnNext(data);
      }}
    >
      {(props) => {
        const { values, setFieldValue, errors, submit } = props;

        return (
          <Grid container className={classesKym.mainGridContainer}>
            <Formx className={`formulario ${classesKym.FormKym}` + " v1"}>
              <Grid item className={`boxForm ${classesKym.gridSectionForm}`}>
                <Grid
                  container
                  spacing={2}
                  mt={1}
                  className={classesKym.gridForm}
                >
                  {section.map((sec, j) => {
                    if (sec.hasOwnProperty("radio")) {
                      if (initialValue[sec.radio.name] === sec.radio.value) {
                        return sectionBuild(sec, j, {
                          values,
                          setFieldValue,
                          errors,
                        });
                      }
                    } else {
                      return sectionBuild(sec, j, {
                        values,
                        setFieldValue,
                        errors,
                      });
                    }
                  })}
                </Grid>
                <FooterContainer>
                  {footer.map((field) => (
                    <div key={field.props.id}>
                      <GenericInput {...addErr(field, errors)} />
                      <ErrorMessage name={field.props.name} errors={errors} />
                    </div>
                  ))}
                </FooterContainer>
              </Grid>
              <Grid item>
                <Stack
                  direction="row"
                  justifyContent="end"
                  mt={10}
                  className={classesKym.stackButtons}
                >
                  {btns.map((btn) => (
                    <div style={{ width: "30%" }} key={btn.id}>
                      <GenericButton
                        props={btn}
                        btnBefore={btnBefore}
                        btnNext={btnNext}
                        submit={submit}
                      />
                    </div>
                  ))}
                </Stack>
              </Grid>
            </Formx>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default Form;
