import "../App.css";
import FormV2 from "./formV2";
import Form from "./form";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosClient from "../actions/axiosClient";
import { Typography, Box, Grid } from "@mui/material";
import { format } from "date-fns";
import themeKym from "../utils/themes/ThemeKym";

const FormContainer = () => {
  const [data, setDataForm] = useState(null);
  const [step, changeStep] = useState(0);
  const [module, setModule] = useState(false);
  const [isBack, setIsBack] = useState(false);
  const search = useLocation().search;
  const parentUrl = document.location.ancestorOrigins[0];
  const formId = new URLSearchParams(search).get("form_id");
  const country = new URLSearchParams(search).get("country");
  const email = new URLSearchParams(search).get("email");
  const classesKym = themeKym();

  const findFieldsValues = (fields, values) => {
    fields.forEach(({ props }) => {
      if (props.type === "column") {
        findFieldsValues(props.fields, values);
      } else {
        props.defaultValue = values[props.name];
        props.value = values[props.name];
      }
    });
  };

  const formatValue = (type, value) => {
    if (type == "date") {
      return format(new Date(value), "dd/MM/yyyy");
    } else {
      return value;
    }
  };

  const getValueForSection = (sec, values) => {
    let values_ = {};
    sec.fields.forEach((field) => {
      let name;
      if (field.props.type == "column") {
        for (let i in field.props.fields) {
          name = field.props.fields[i].props.name;
          if (field.props.fields[i].props.type == "checkbox") {
            values_[name] = values[name].length > 0;
          } else {
            values_[name] = values[name];
          }
        }
      } else {
        name = field.props.name;
        if (field.props.type == "checkbox") {
          values_[name] = values[name].length > 0;
        } else {
          values_[name] = formatValue(field.props.type, values[name]);
        }
      }
    });
    return values_;
  };

  const visibleSection = (sec, values) => {
    if (sec.hasOwnProperty("radio")) {
      if (values[sec.radio.name] == sec.radio.value) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  };

  const insertUser = async (data, module = false) => {
    if (module == "Working-Capital") {
      data["module"] = module;
    }
    return axiosClient
      .post(`${process.env.REACT_APP_DOMAIN}/user`, { ...data })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  };

  const updateUser = async (data, email) => {
    return axiosClient
      .put(`${process.env.REACT_APP_DOMAIN}/user?email=${email}`, { ...data })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  };

  const userRequest = async (dataValues) => {
    if (module == "Working-Capital") {
      dataValues.email = dataValues.Negocio["contact-information"].email;
      dataValues.createDate = new Date().toISOString();
      const response = await insertUser(dataValues, module);
      if (response.status != 200) {
        response.data == "The user already exists"
          ? alert("Usuario ya registrado")
          : alert("favor de intentar mas tarde");
      } else {
        window.parent.postMessage(
          { message: "CREATE", data: dataValues, code: 200 },
          parentUrl
        );
      }
    } else {
      if (!data.email) {
        dataValues.email = data.email = email;
        await insertUser(dataValues);
      } else {
        updateUser(dataValues, data.email);
      }
    }
  };

  const nextStep = async (values) => {
    let dataValues = {};
    let sectionData = {};
    const stageName = data.forms[step].stageName;
    const checkpoint =
      data.forms[step].checkpoint == null ? true : data.forms[step].checkpoint;
    const section = data.forms[step].section;
    const footer = data.forms[step].footer
      ? data.forms[step].footer.fields
      : [];
    section.map(async (sec) => {
      let pass = visibleSection(sec, values);
      if (pass) {
        sectionData[sec.sectionName] = getValueForSection(sec, values);
        findFieldsValues(sec.fields, values);
      }
    });
    footer.map(async (e) => {
      if (e.props.type == "checkbox") {
        sectionData[e.props.name] = values[e.props.name].length > 0;
      } else {
        sectionData[e.props.name] = values[e.props.name];
      }
    });
    dataValues[stageName] = sectionData;
    dataValues.email = data.email;
    dataValues.formId = data.formId;
    dataValues.currentStep = step + 1;
    if (step + 1 > data.currentStep) data.currentStep = step;
    data.type_of_company = values.type_of_company;
    if (checkpoint) {
      userRequest(dataValues);
    }

    if (step < parseInt(data.steps) - 1) {
      data["forms"][step]["status"] = "Validate";
      changeStep(step + 1);
    } else {
      window.parent.postMessage(
        { message: "CREATE", data: dataValues, code: 200 },
        parentUrl
      );
      window.parent.postMessage(
        { message: "SUCCESS", data: data, code: 200 },
        parentUrl
      );
    }
  };

  const beforeStep = () => {
    setIsBack(true);
    changeStep(step - 1);
  };

  const deleteTable = (index) => {
    data.forms[step].section[0].table.data.splice(index, 1);
    setIsBack(false);
    setDataForm({ ...data });
  };

  const saveTable = (item) => {
    let id = item.id;
    let saveData = data;
    if (id != undefined) {
      delete item.id;
      saveData.forms[step].section[0].table.data[id] = item;
    } else {
      saveData.forms[step].section[0].table.data.push(item);
    }
    setDataForm(saveData);
  };

  useEffect(() => {
    axiosClient
      .get(
        `${process.env.REACT_APP_DOMAIN}/form?formId=${formId}&country=${country}&email=${email}`
      )
      .then((res) => {
        setDataForm(res.data);
        setModule(res.data.proyectName);
        if (res.data.currentStep) {
          if (res.data.currentStep + "" === res.data.steps + "") {
            window.parent.postMessage(
              { message: "SUCCESS", data: {}, code: 200 },
              parentUrl
            );
          } else {
            changeStep(res.data.currentStep);
          }
        }
      });
  }, [formId, country, email, parentUrl]);

  if (data === null) {
    return (
      <div className="loading">
        <h1>Loading</h1>
        <div className="loadingText"></div>
      </div>
    );
  }

  const countryCheck = (country) => {
    switch (country) {
      case "mx":
      case "bo":
        return (
          <FormV2
            form={data.forms[step]}
            dataForms={data.forms}
            steppers={data.steppers ?? false}
            btnNext={nextStep}
            btnBefore={beforeStep}
            isBack={isBack}
            deleteTable={deleteTable}
            saveTable={saveTable}
            country={data.country}
          />
        );
      default:
        return (
          <Grid container>
            <Grid item xs={1} md={12}></Grid>
            <Grid item xs={1} md={12}>
              <Box mt={3} sx={{ height: "100%" }}>
                <Box ml={3} className={classesKym.boxTitleContainer}>
                  <Typography
                    align="center"
                    className={
                      classesKym[`${data.forms[step].classTitle}`] +
                      " " +
                      classesKym.Title
                    }
                    sx={{
                      fontSize: {
                        md: 42,
                        sm: 25,
                      },
                    }}
                    variant="h4"
                    gutterBottom
                    component="div"
                  >
                    {data.forms[step].title}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h5"
                    className={
                      classesKym[`${data.forms[step].classSubtitle}`] +
                      " " +
                      classesKym.Subtitle
                    }
                    sx={{
                      fontSize: {
                        md: 16,
                        sm: 12,
                      },
                    }}
                    gutterBottom
                    component="div"
                    mt={3}
                  >
                    {data.forms[step].subtitle}
                  </Typography>
                </Box>
                <Form
                  form={data.forms[step]}
                  btnNext={nextStep}
                  btnBefore={beforeStep}
                />
              </Box>
            </Grid>
          </Grid>
        );
    }
  };

  return <div className="App">{countryCheck(country)}</div>;
};

export default FormContainer;
